import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/header.jsx";

const ProductsListing = () => {
  const location = useLocation();
  const ProductsList = [
    {
      productId: 15,
      productBg: "/media/products/loreal/loreal-main.png",
      productLogo: "/media/products/loreal/loreal-white.jpg",
      productOverview:
        "The Trade Management System is a user-friendly web app that simplifies budget management, BSR generation, and claim settlements. It streamlines financial operations, ensuring transparency and efficient financial management.",
        productTitle: "L'oreal SYNC",
    },
    {
      productId: 16,
      productBg: "/media/products/iac/iac-bg.png",
      productLogo: "/media/products/iac/logo.png",
      productOverview:
        "Maximize salesforce effectiveness and monitor sales/distribution in FMCGs and other industries.",
        productTitle: "S&D IAC",
    },
    {
      productId: 2,
      productTitle: "PharmaTrack",
      productBg: "/media/products/pharma-track/bg-img.webp",
      productLogo: "/media/products/pharma-track/logo.webp",
      productOverview:
        "A web-based system and mobile-based application designed for pharmaceutical companies to track sales on a real-time basis, along with the performance and real time engagement of medical sales representatives. The application features active dashboards, audio-video conferencing, doctors/representative ratings, team/tasks management, performance metrics, real-time communication, tracking, alerts, chats, graphs, meeting follows ups and more.",
    },
    {
      productId: 1,
      productBg: "/media/products/pap/pap-bg-img.webp",
      productLogo: "/media/products/pap/logo.webp",
      productOverview:
        "Patient Access Program is designed to facilitate patients from marginalized communities who are unable to afford the full price of the medicines they need.",
      productTitle: "Patient Access Program",
    },
    {
      productId: 3,
      productTitle: "MeetUp",
      productBg: "/media/products/meetup/bg-img.webp",
      productLogo: "/media/products/meetup/logo.webp",
      productOverview:
        "A cloud-based peer-to-peer communication software platform that allows users to create virtual spaces that powers all of your communication needs, including meetings, chat, phone, webinars, and online events. It has screen sharing and document sharing among other capabilities and can be a great tool for distance learning. Our application gallery allows users to experience features of all their favourite applications.",
    },
    {
      productId: 5,
      productTitle: "Education Portal",
      productBg: "/media/products/education-portal/bg-img.webp",
      productLogo: "/media/products/education-portal/logo.webp",
      productOverview:
        "This is a web and mobile-based management system for schools, colleges, and universities.",
    },
    {
      productId: 4,
      productTitle: "HealthE",
      productBg: "/media/products/healthe/bg-img.webp",
      productLogo: "/media/products/healthe/logo.webp",
      productOverview:
        "A state of the art health care mobile application covering patients, doctors, pharmaceuticals, labs, hospitals, first responders and more.",
    },
    {
      productId: 8,
      productTitle: "Vits",
      productBg: "/media/products/vits/bg-img.webp",
      productLogo: "/media/products/vits/logo.webp",
      productOverview:
        "Vits is an Online Portal to fulfill the need of Human Resource Management of companies.",
    },
    // {
    //   productId: 6,
    //   productTitle: "Smart S&D",
    //   productBg: "/media/products/smart-s&d/bg-img.webp",
    //   productLogo: "/media/products/smart-s&d/logo.webp",
    //   productOverview:
    //     "S&D helps businessed control and monitor their sales and distribution process through a simple mobile application. The app is designed to apply and maintain salesforce effectiveness across business functions to help maximize productivity and profitability.",
    // },
    // {
    //   productId: 7,
    //   productTitle: "Smart S&D Pharma",
    //   productBg: "/media/products/pharma-s&d/bg-img.webp",
    //   productLogo: "/media/products/pharma-s&d/logo.webp",
    //   productOverview:
    //     "A mobile-based tracking application specifically designed for pharmaceutical companies to track, control and monitor sales and distribution of their medicines. The application also allows performance tracking of their on-ground sales personnel and maintain salesforce effectiveness across business functions to help maximize productivity and profitability.",
    // },

    {
      productId: 9,
      productTitle: "Sales & Predict",
      productBg: "/media/products/sales&predict/bg-img.webp",
      productLogo: "/media/products/sales&predict/logo.webp",
      productOverview:
        "This system covers the scope to predict future sales and provide recommendations for business development.",
    },
    {
      productId: 10,
      productTitle: "Cancer Classification System",
      productBg: "/media/products/cancer-classification/bg-img.webp",
      productLogo: "/media/products/cancer-classification/logo.webp",
      productOverview:
        "The Cancer Classification is a web-based AI system that detects 7 different types of skin cancer. It takes dermatoscopic images as an input to identify the type of skin cancer, with an accuracy rate of 97%.",
    },
    {
      productId: 11,
      productTitle: "Crime Detection System",
      productBg: "/media/products/crime-detection/bg-img.webp",
      productLogo: "/media/products/crime-detection/logo.webp",
      productOverview:
        "A web-based product designed to detect criminal activity, with the help of Artificial Intelligence.",
    },
    {
      productId: 12,
      productTitle: "Facial Recognition System",
      productBg: "/media/products/face-recognition/bg-img.webp",
      productLogo: "/media/products/face-recognition/logo.webp",
      productOverview:
        "This product detects faces using Artificial Intelligence and recognizes facial expressions.",
    },
    {
      productId: 13,
      productTitle: "Facial Expression Recognition System",
      productBg: "/media/products/facial-expression-recognition/bg-img.webp",
      productLogo: "/media/products/facial-expression-recognition/logo.webp",
      productOverview:
        "A web-based system which is able to detect face and then recognize it's expressions.",
    },
    {
      productId: 14,
      productTitle: "Sign Language Detection System",
      productBg: "/media/products/sign-language/bg-img.webp",
      productLogo: "/media/products/sign-language/logo.webp",
      productOverview:
        "The scope of this project is to detect signs of American Sign Language (ASL).",
    },
  ];
  return (
    <React.Fragment>
      <Header locationName={location.pathname} />
      {/* <LandingSlider /> */}
      <section id="products-listing-sec">
        {/* <img className="img-bg"  src={productBg} alt=""/> */}
        <div className="container">
          <Row className="products-listing-desc mx-0">
            <Col md={9}>
              <h4 className="title-blue-bar ml-0">Our Products</h4>
              <p className="overview-black ml-0">
                Best solutions for your company
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="product-listing d-flex flex-column justify-content-center align-items-center">
                {ProductsList.map((product, index) => (
                  <div className="product-details col-12 d-flex flex-wrap my-4 px-0">
                    <div className="product-image col-md-5 px-0">
                      <img src={product.productBg} alt="" />
                      <div className="product-logo">
                        <img src={product.productLogo} alt="" />
                      </div>
                    </div>
                    <div className="product-desc col-md-7 d-flex flex-column justify-content-center align-items-center">
                      <div className="product-block">
                        <h2>
                          {product.productTitle}
                          <sup className="ml-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#ffffff"
                              class="bi bi-badge-tm"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.295 11V5.995H7V5H2.403v.994h1.701V11h1.19zm3.397 0V7.01h.058l1.428 3.239h.773l1.42-3.24h.057V11H13.5V5.001h-1.2l-1.71 3.894h-.039l-1.71-3.894H7.634V11h1.06z"></path>
                              <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"></path>
                            </svg>
                          </sup>
                        </h2>
                        <p className="product-overview my-4">
                          {product.productOverview}
                        </p>
                        <Link
                          to={"/product-details/" + product.productId}
                          className="more-btn"
                        >
                          See more
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ProductsListing;
