import React from 'react'
import Main from "../components/chatModule/Main"
import {useState} from 'react';
import Avatar from '@mui/material/Avatar';



const chatbot = () => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    setIsShown(current => !current);
    
    handlingScroll();

  };

const enableScroll=()=>{
  // document.body.style.overflow = 'unset';
  // document.ChatbotView.style.overflow="unset"
}
const disbaleScroll=()=>{
  if (typeof window != 'undefined' && window.document) {
    // document.body.style.overflow = 'hidden';
    // document.ChatbotView.style.overflow="unset"
  }
}

function handlingScroll() {
    isShown?enableScroll(): disbaleScroll()
}

function handleMouseOver() {
  console.log("Mouse is over the div.");
  document.body.style.overflow = 'hidden';
  document.body.style.overflowY = 'auto';
  // You can perform any actions or trigger events here.
}

function handleMouseOut() {
  console.log("Mouse is out of the div.");
  document.body.style.overflow = 'auto';
  document.body.style.overflowX = 'hidden';

  // Additional actions can be performed when the mouse leaves the div.
}
const handleWheel = (e) => {
  console.log("Mouse is out of the div.");

  e.preventDefault(); // Prevent default scrolling behavior
};
  return (
    <div className="chtabotMain" onWheel={handleWheel}>
      <button className="chatBotBtn" onClick={handleClick} >
            <Avatar alt="Chat bot" src={"/media/boss3.png"} />
      </button>
      <Main className="ChatbotView" isShown={isShown}/>
    </div>
    )
}

export default chatbot
