import React from "react";
import Slider from "react-slick";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Slider1 from "../media/Slider1.jpg";
import Slider2 from "../media/Slider2.jpg";
import Slider3 from "../media/Slider3.jpg";
import Slider4 from "../media/Slider4.jpg";
import Slider5 from "../media/Slider5.jpg";
import Slider6 from "../media/Slider6.jpg";

const LandingSlider = () => {
  var settings = {
    infinite: true,
    slidesToScroll: 1,
    slidesToShow:1,
    autoplay: true,
    centerMode: true,
    centerPadding: '0px',
    autoplaySpeed: 3000,
    dots: true,
    arrows: true,
    initial: 1
  };

  var ImageArray = [
    {heading : "Digital Transformation | Innovation | Modernization",
     sub_heading : "Synergizing Tradition with Technology: A Journey of Digital Transformation, Innovation, and Modernization", 
      img :Slider1
    },
    {heading : "Community-driven solutions",
     sub_heading : "Harnessing the collective power of communities to tackle complex problems", 
      img :Slider6
    },
    {heading : "Inclusive technology",
     sub_heading : "Building software that breaks down barriers and brings communities together", 
      img :Slider2
    },
    {heading : "Simplicity at the core",
     sub_heading : "Solving complex problems with software that is intuitive and easy to use", 
      img :Slider3
    },
    {heading : "Collaborative innovation",
     sub_heading : "Innovative software that brings communities together to solve even the toughest challenges", 
      img :Slider4
    },
    {heading : "The power of connection",
     sub_heading : "Connecting communities and simplifying complex problems with cutting-edge software technology", 
      img :Slider5
    },
  ]

  return (
    <section id="slider-sec">
      <Slider {...settings}>

      {ImageArray.map((img)=>{


        return (<div className="slide-content-block">
          <LazyLoadImage alt="slider-alt" src={img.img} effect="black-and-white"/>
          {/* <img src="/media/slide6.webp" alt="" /> */}
          <div className="container">
            <div className="col-12 p-0 d-flex flex-wrap justify-content-start">
              <div className="slide-content col-md-6">
                <h4 className="title-yellow-bar">Digital Landscape</h4>
                <h4 className="sub-title">{img.heading}</h4>
                <p>
                  <svg width="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-quote-left fa-w-16 fa-2x mb-1"><path fill="currentColor" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z" className=""></path></svg>
                  {img.sub_heading}
                  <svg width="10px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-quote-right fa-w-16 fa-2x"><path fill="currentColor" d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z" className=""></path></svg>
                </p>
              </div>
            </div> 
          </div> 
          </div>)
      })
    }
      </Slider>
    </section>
  );
};

export default LandingSlider;
