import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/header.jsx";
import ButtonMailto from "./mail-to.jsx";

const Career = () => {
  const location = useLocation();
  let currentDay = new Date();
  let currentYear = currentDay.getFullYear();
  let currentMonth = currentDay.getMonth() + 1;
  let currentDate = currentDay.getDate();
  let today = currentYear + "-" + currentMonth + "-" + currentDate;

  return (
    <React.Fragment>
      <Header locationName={location.pathname} />
      <div className="career-banner-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 d-flex flex-wrap justify-content-between align-items-center">
              <div className="col-lg-5">
                <div className="banner-content w-100">
                  <h3>Join Us</h3>
                  <h6>Integrating Communities</h6>
                  <p>
                    "Providing the simplest solution for the most complex
                    problems"
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="banner-img-area w-100">
                  <img
                    className="main-img"
                    src="/media/career-crowd.webp"
                    alt=""
                  />
                  <img
                    className="left-img"
                    src="/media/career-zoom.webp"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="career-sec">
        <div className="container-fluid">
          <Row className="m-0">
            <Col xs={12} className="career-content">
              <h4 className="title-blue-bar ml-5 mt-3 mb-4">Join Our Team</h4>
              <p className="overview-black w-75 ml-5 mb-5">
                To apply, please email careers@dls-global.com and mention the
                applying position title in email subject field.
              </p>
            </Col>
          </Row>
          <Row className="m-0">
            <Col xs={12} className="px-0">
              <div className="blue-border d-flex flex-wrap justify-content-around align-items-center text-sm-center text-md-left p-5">
                <p className="job-icon-blue d-flex justify-content-center align-items-center">
                  <img src="/media/icon3.svg" alt="" className="job-icon-img" />
                </p>
                <div className="job-overview col-9">
                  <h4 className="job-title-blue pb-3">Content Writer</h4>
                  <p className="job-desc overview-black mb-4 w-100">
                    We are looking for a Content Writer to join our team. A
                    person responsible for creating print and digital content
                    that provides information or showcases the company’s
                    products and services.
                  </p>
                  <h4 className="job-deadline-blue pb-4">
                    Apply Before: {today}
                  </h4>
                  <Link to={"./job-details/1"} className="job-details-btn">
                    Read More
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="m-0">
            <Col xs={12} className="px-0">
              <div className="blue-border d-flex flex-wrap justify-content-around align-items-center text-sm-center text-md-left p-5">
                <p className="job-icon-blue d-flex justify-content-center align-items-center">
                  <img src="/media/icon3.svg" alt="" className="job-icon-img" />
                </p>
                <div className="job-overview col-9">
                  <h4 className="job-title-blue pb-3">Graphic Designer</h4>
                  <p className="job-desc overview-black mb-4 w-100">
                    We are looking for a Graphic Designer to create engaging and
                    on-brand graphics for a web, applications and other
                    projects.
                  </p>
                  <h4 className="job-deadline-blue pb-4">
                    Apply Before: {today}
                  </h4>
                  <Link to={"./job-details/2"} className="job-details-btn">
                    Read More
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="m-0">
            <Col xs={12} className="px-0">
              <div className="blue-border d-flex flex-wrap justify-content-around align-items-center text-sm-center text-md-left p-5">
                <p className="job-icon-blue d-flex justify-content-center align-items-center">
                  <img src="/media/icon3.svg" alt="" className="job-icon-img" />
                </p>
                <div className="job-overview col-9">
                  <h4 className="job-title-blue pb-3">Intern</h4>
                  <p className="job-desc overview-black mb-4 w-100">
                  We are currently providing internships in the following two domains:
                  <ul>
                    <li>Web Application Development using React Js.</li>
                    <li>Cross-Platform Mobile Application Development.</li>
                  </ul>
                  </p>
                  <h4 className="job-deadline-blue pb-2">
                    Apply Before: N/A
                  </h4>
                  <ButtonMailto
                    label="Apply Now"
                    mailto="mailto:careers@dls-global.com"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Career;
