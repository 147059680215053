import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';


export default function AlertMessage({severity,message,open = false,setOpen}) {
//   const [open, setOpen] = React.useState(false);

const [state, setState] = React.useState({
  vertical: 'top',
  horizontal: 'right',
});

const { vertical, horizontal } = state;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        anchorOrigin={{ vertical : "top" , horizontal : "right" }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        key={vertical + horizontal}
        anchorOrigin={{ vertical, horizontal }}
        >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
    </div>
  );
}