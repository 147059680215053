import React, {memo} from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';


const SoundWaves = ({
  toggleMic
}) => {
 
  return (
    <div className="sound-waves">

      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="sound-arrow">
        <IconButton onClick={()=>toggleMic()}>
        <ArrowUpwardIcon />
          </IconButton> 
        </div>
    </div>
  );
};



export default memo(SoundWaves);
