import React from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../components/header.jsx";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const CompleteEngagementModels = (props) => {

  const { id } = useParams();
  const contentId = parseInt(id)

  return (
    <React.Fragment>
      <Header />
      <section id="complete-engag-models-sec">
        <div className="container-fluid">
          <Row className="m-0">
            <Col>
              <h4 className="title-blue-bar ml-5">Engagement Models</h4>
              <p className="overview-black ml-5 pr-5 w-50">
                Because every client is unique, we have developed engagement
                models tailored for businessesfrom start-ups to large companies
                and organizations
              </p>
            </Col>
          </Row>
          {contentId === 1 ?
            <Row id="model1" className="m-5 single-model">
              <Col className="px-0">
                <div className="engag-model-block d-flex flex-column justify-content-center align-items-center p-5">
                  <p className="engag-model-icon blue d-flex flex-column justify-content-center align-items-center">
                    <img
                      src="../media/icon1.png"
                      alt=""
                      className="engag-model-icon-img"
                    />
                  </p>
                  <div className="complete-engag-model-content">
                    <h4 className="engag-model-heading blue text-center py-3">
                      Consulting Service
                    </h4>
                    <p>Recommended For:</p>
                    <ul>
                      <li>
                        <h5>Smart Delegation</h5>
                        <p>
                          Get the help you need to translate your business goals
                          to technology plans before you build your team.
                        </p>
                      </li>
                      <li>
                        <h5>Situational assessment</h5>
                        <p>
                          Receive a second opinion about challenges you are facing
                          and recommendations to move forward.
                        </p>
                      </li>
                      <li>
                        <h5>Prepare to partner</h5>
                        <p>
                          We can help you define a project in a way that raises
                          your comfort working with a technology partner on your
                          software development needs.
                        </p>
                      </li>
                      <li>
                        <h5>Professional Services</h5>
                        <p>
                          DL's consultants are ready to assist your company by
                          developing a strategy that aligns with your technical
                          and commercial business requirements in E-Commerce, ERP
                          Systems, HRMS, FinTech, Healthcare, Search Engine
                          Optimization, Data Analytics, Web and Mobile Engagement
                          with your stakeholders. DL's Trusted Advisor Network
                          expands the professional services offerings to provide
                          assessments, strategic planning, consulting, and
                          outcome-based engagements for clients to help drive
                          transformation and optimization for business processes.
                          Our consultants have broad experience and expertise in
                          numerous business domains, technologies, and industries
                          and can provide valuable insight to clients looking for
                          a competitive edge in creating speed to value.
                        </p>
                      </li>
                    </ul>
                    <h4 className="engag-model-heading blue">Why engage DL?</h4>
                    <ul>
                      <li>When you need specialized expertise</li>
                      <li>
                        Your business needs to make strides in becoming optimized,
                        creating new products, movingfaster in digital world,
                        build an infrastructure for the new digital economy or
                        optimize its current processes, then we are ready to help.
                      </li>
                      <li>Temporary help during onetime projects</li>
                      <li>
                        You want to jump-start a new technical initiative and
                        accelerate the effort for a shorter time-to-value.
                      </li>
                      <li>
                        If you want to gain external, objective advice and
                        recommendations, DL has several assessments for
                        technology, security, business processes, package
                        evaluations, process improvement, and automation that can
                        quickly be leveraged by your company to make a financial
                        and operational difference.
                      </li>
                      <li>
                        {" "}
                        When you want a trusted partner for your business needs
                      </li>
                      <li>
                        Partnering with DL can provide you with the resources you
                        need to expand and optimize your operations
                      </li>
                    </ul>
                    <h4 className="engag-model-heading blue">Our process</h4>
                    <p>
                      Our consulting engagements are based on a standard, phased
                      approach, and tailored to your specific needs.
                    </p>
                    <h4 className="engag-model-heading blue">What you get?</h4>
                    <ol>
                      <li>
                        <h5>The Best Consultants</h5>
                        <p>
                          Our consultants have broad experience and expertise in
                          numerous business domains and use of technology to
                          improve business operations, optimize processes,
                          increase automation, and engage in increasing digital
                          transformation processes.
                        </p>
                      </li>
                      <li>
                        <h5>Our Broad Expertise</h5>
                        <p>
                          DL’s consultants will leverage the company's years of
                          experience delivering several projects to find the best
                          approach to your business needs.
                        </p>
                      </li>
                      <li>
                        <h5>Achieving business outcomes</h5>
                        <p>
                          Our focus on the fundamental business outcomes helps
                          ensure the appropriate and efficient use of people,
                          process, and technology to meet your needs.
                        </p>
                      </li>
                      <li>
                        <h5>Continuity of care</h5>
                        <p>
                          The knowledge our consultants gain during engagement can
                          be transferred to our engineers to accelerate subsequent
                          software development effort. Likewise, the consultant
                          can review future projects to ensure they are on track
                          to achieve the originally desired outcome.
                        </p>
                      </li>
                    </ol>
                    <p className="mt-5 ml-4">
                      We can help you ensure the best use of technology to advance
                      your business goals.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            : contentId === 2 ?
              <Row id="model2" className="my-5 single-model">
                <Col className="px-0">
                  <div className="engag-model-block d-flex flex-column justify-content-center align-items-center p-5">
                    <p className="engag-model-icon yellow d-flex flex-column justify-content-center align-items-center">
                      <img
                        src="../media/icon2.png"
                        alt=""
                        className="engag-model-icon-img"
                      />
                    </p>
                    <div className="complete-engag-model-content">
                      <h4 className="engag-model-heading yellow text-center py-3">
                        Managed Team Extension
                      </h4>
                      <p>
                        With the Team Extension Model, our developers become an
                        integral part of your team during the entire project. We
                        carefully select each team member based on the requirements
                        and expertise you need. They immerse themselves in your
                        project, maintaining your company culture and working in
                        line with your strategic goals.
                      </p>
                      <p>This solution works best for:</p>
                      <ul>
                        <li>
                          <h5>
                            Developing New Modules/Applications/Projects and their
                            seamless deployment
                          </h5>
                          <ul>
                            <li>
                              You get dedicated developers with strong technical
                              skills who can help you to accelerate development
                              and/or integration of your product.
                            </li>
                            <li>Saving Time and Resources.</li>
                            <li>
                              You find it tough to hire and secure excellent,
                              cost-effective IT talent at your location or do not
                              want to spend time on onboarding.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <h5>Long-Term Cooperation</h5>
                          <p>
                            Our extension team works on your project only and under
                            the same rules as your own team. You can rely on them
                            24/7 and expect long-term and fruitful cooperation.
                          </p>
                        </li>
                        <li>
                          <h5>Getting more for less budget</h5>
                          <p>
                            We build balanced agile teams who reach your goals in
                            the fastest way with optimal resources. You are
                            guaranteed a price, year to year, which protects you
                            from running over the budget.
                          </p>
                        </li>
                        <li>
                          <h5>Ongoing Bug Fixes & Support</h5>
                          <p>
                            We recommend the best-suited technologies and provide
                            continuous integration of new features in your product.
                          </p>
                        </li>
                        <li>
                          <h5>Quality Assurance Expertise</h5>
                          <p>
                            We lower your risk of releasing an immature software
                            product, thus protecting you against additional costs.
                          </p>
                        </li>
                        <li>
                          <h5>Project Life Cycle</h5>
                          <p>
                            The project life cycle clearly does not end once your
                            software has been developed, deployed, and launched. We
                            take care of your project by continuously testing and
                            incrementally improving it as your needs and
                            requirements evolve.
                          </p>
                          <ul>
                            <li>
                              <h5>Initial Meeting</h5>
                              <p>
                                We start with a detailed discussion of your ideas
                                and needs.
                              </p>
                            </li>
                            <li>
                              <h5>Solution</h5>
                              <p>
                                Our team drafts a timeline and approximate project
                                plan.
                              </p>
                            </li>
                            <li>
                              <h5>ITeam Composition</h5>
                              <p>
                                We employ talented architects, developers,
                                engineers, testers, and designers, who are experts
                                in all emerging technologies.
                              </p>
                            </li>
                            <li>
                              <h5>Development</h5>
                              <p>
                                Transparent processes, Agile methodology, daily
                                meetings, regular deliverables, and a dedicated
                                Project Coordinator make the development process
                                smooth and transparent.
                              </p>
                            </li>
                            <li>
                              <h5>Deployment</h5>
                              <p>
                                On-time and smooth delivery ensures steady operation
                                of your product.
                              </p>
                            </li>
                            <li>
                              <h5>Support</h5>
                              <p>
                                Our experts take care of your product and help it
                                evolve rapidly with continuous development of new
                                features and updates.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <h4 className="engag-model-heading blue">Our process</h4>
                      <p>
                        We have developed a unique recruitment process, based on
                        industry best practices, that enables our recruitment team
                        to find the best experts in the market. This vital part of a
                        project life cycle ensures a successful result.
                      </p>
                      <h4 className="engag-model-heading blue">What you get?</h4>
                      <ol>
                        <li>
                          <h5>The Best Consultants</h5>
                          <p>
                            Our dedicated team of talented developers under the
                            management of an experienced Project Coordinator will
                            extend your own team to increase output while reducing
                            cost.
                          </p>
                        </li>
                        <li>
                          <h5>24/7 Support and Assistance</h5>
                          <p>
                            Each project has a Project Coordinator who oversees the
                            entire development process and is always ready to answer
                            your questions.
                          </p>
                        </li>
                        <li>
                          <h5>Our Expertise</h5>
                          <p>
                            We use Agile methodology to ensure that the project
                            progresses efficiently and on time.
                          </p>
                        </li>
                        <li>
                          <h5>More for Less</h5>
                          <p>
                            We guarantee a price, year to year, to protect you from
                            running over budget.Our team becomes as vested as your
                            own team in the success of your project.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </Col>
              </Row>
              : contentId === 3 ?
                <Row id="model3" className="my-5 single-model">
                  <Col className="px-0">
                    <div className="engag-model-block d-flex flex-column justify-content-center align-items-center p-5">
                      <p className="engag-model-icon dark-blue d-flex flex-column justify-content-center align-items-center">
                        <img
                          src="../media/icon3.png"
                          alt=""
                          className="engag-model-icon-img"
                        />
                      </p>
                      <div className="complete-engag-model-content">
                        <h4 className="engag-model-heading dark-blue text-center py-3">
                          Agile Squads
                        </h4>
                        <p>Recommended For:</p>
                        <ul>
                          <li>
                            <h5>Smart Delegation</h5>
                            <p>
                              No need to assign internal staff to build a project from
                              scratch.
                            </p>
                          </li>
                          <li>
                            <h5>Faster Results</h5>
                            <p>
                              With AgileSquads approach, short interactions guarantee
                              that every step of yourproject is implemented on time.
                            </p>
                          </li>
                          <li>
                            <h5>Transparency & Control</h5>
                            <p>
                              It is easy to hand over the requirements to a
                              ready-to-go Agile Squad and check in with them
                              periodically. Get a time and effort estimate for your
                              requirements and let us assemble an Agile Squad, managed
                              by an experienced Squad Lead, to develop products from
                              scratch according to your time and budget.
                            </p>
                          </li>
                        </ul>
                        <p>This solution works best for:</p>
                        <ul>
                          <li>
                            <h5>Creating a Custom Software Product From Scratch</h5>
                            <p>
                              You need new products but have no one available to build
                              them? Our developers look forward to helping you.
                            </p>
                          </li>
                          <li>
                            <h5>Avoiding Project-Management Risk</h5>
                            <p>
                              Wonder how to best build a new product? We will deliver
                              your project within specific requirements, time, and
                              budget.
                            </p>
                          </li>
                          <li>
                            <h5>Getting More For Less</h5>
                            <p>
                              DL’s core engineering process brings value to you by
                              applying innovation to speed up development and/or
                              integration of your project.
                            </p>
                          </li>
                          <li>
                            <h5>Hand-Over Approach</h5>
                            <p>
                              Just hand over your requirements to our ready-to-go
                              Agile Squad, then check in with them periodically for
                              updates.
                            </p>
                          </li>
                        </ul>
                        <h4 className="engag-model-heading blue">Our process</h4>
                        <p>
                          Our development process is the core part of a project life
                          cycle. We have developed it based on industry best
                          practices, thus enabling our teams to deliver projects of
                          exceptional quality.
                        </p>
                        <h4 className="engag-model-heading blue">What you get?</h4>
                        <ol>
                          <li>
                            <h5>The Best Talent</h5>
                            <p>
                              You get a ready-to-go Agile Squad of talented developers
                              and an experienced Squad Leader with client domain
                              expertise.
                            </p>
                          </li>
                          <li>
                            <h5>Responsible Squad Leader</h5>
                            <p>
                              The Squad Leader is an experienced person who goes
                              beyond Project Management, by knowing how to design the
                              solution, plan out the work, and kickstart the
                              development process, always focusing on the success of
                              your project.
                            </p>
                          </li>
                          <li>
                            <h5>Our Expertise</h5>
                            <p>
                              We use Agile methodology to ensure that the project
                              progresses efficiently and on time.
                            </p>
                          </li>
                          <li>
                            <h5>More for Less</h5>
                            <p>
                              We guarantee a price, year to year, to protect you from
                              running over budget. We can help you ensure the best use
                              of technology to advance your business.
                            </p>
                            {/* <p>
                        We can help you ensure the best use of technology to
                        advance your business.
                      </p> */}
                          </li>
                        </ol>
                      </div>
                    </div>
                  </Col>
                </Row>
                :
                <Row id="model3" className="my-5 single-model">
                  <Col className="px-0" style={{ textAlign: "center" }}>
                    <p style={{ fontSize: "18px", fontWeight: "600" }}>No content found.</p>
                  </Col>
                </Row>

          }
          <Row id="model3" className="my-5 single-model">
            <Col className="px-0" style={{ textAlign: "center" }}>
              <Link to={"/"} className="more-btn">
                Back to Home
              </Link>
            </Col>
          </Row>
        </div>
      </section>
      {/* <Footer /> */}
    </React.Fragment>
  );
};

export default CompleteEngagementModels;
