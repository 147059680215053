import React , { useState, useEffect } from 'react';
// import ReactGA from 'react-ga';
import ScrollToTop from "./components/scrollToTop.jsx";
import LandingPage from './containers/landing-page';
import AboutUs from './containers/about-us.jsx';
import RequestQuote from './containers/request-quote.jsx';
import ProductsListing from './containers/products-listing';
import Career from "./containers/career.jsx";
import ContactUs from "./containers/contact-us.jsx";
import ProductDetails from "./components/product-details.jsx";
import CompleteClientTestimonials from "./containers/complete-client-testimonials.jsx";
import CompleteEngagementModels from "./containers/complete-engagement-models.jsx";
import JobDetails from "./containers/job-details.jsx";
import NotFound from "./components/notFound.jsx";
import Footer from './components/footer.jsx';
// import RequestQuote from './containers/request-quote';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { bootstrap } from 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import { CssBaseline } from '@mui/material';

// const TRACKING_ID = "G-SW143J59LH";
// ReactGA.initialize(TRACKING_ID);

function App() {
  // const [chatbot , setChatbot] = useState(0);
  // var date = new Date();
  // useEffect(() => {
  //   setChatbot(1)
  //   alert(date , "date");
  // });

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path="/about-us" component={AboutUs} />
          <Route path="/our-products" component={ProductsListing} />
          <Route path="/career" component={Career} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/product-details/:id" component={ProductDetails} />
          <Route path="/job-details/:id" component={JobDetails} />
          <Route path="/client-testimonials" component={CompleteClientTestimonials} />
          <Route path="/complete-engagement-models/:id" component={CompleteEngagementModels } />
          <Route path="/request-quote" component={RequestQuote } />
          <Route exact path="/" component={LandingPage} />
          <Route path="/not-found" component={NotFound} />
        </Switch>
      </ScrollToTop>
      {/* {chatbot === 1 ?  */}
        <Footer/>
         {/* : ''
      } */}
      <CssBaseline/>
    </BrowserRouter>
  );
}

export default App;
