import React from "react";
import { Row, Col } from 'react-bootstrap';

const Clients = () => {
    return ( 
        <section id="clients-sec">
            {/* <img className="img-bg" src="/media/clients-bg.svg" alt="" /> */}
            <Row className="client-descript-block">
                <div className="container-fluid">
                    <Col>
                        <h4 className="title-yellow-bar mx-3 mb-3">Our Clients</h4>
                        <p className="overview-white mx-3">Meet our trusted clients and partners</p>
                    </Col>
                </div>
            </Row>
            <Row className="client-list-block">
                <div className="container-fluid text-center">
                    <ul className="row list-unstyled text-center m-0  d-inline-flex justify-content-center ">
                        <li>
                            {/* <span className="client-logo1"></span> */}
                            <img src="/media/clients/1.webp" alt="" />
                        </li>
                        <li>
                            {/* <span className="client-logo2"></span> */}
                            <img src="/media/clients/2.webp" alt="" />
                        </li>
                        <li>
                            {/* <span className="client-logo3"></span> */}
                            <img src="/media/clients/3.webp" alt="" />
                        </li>
                        <li>
                            {/* <span className="client-logo4"></span> */}
                            <img src="/media/clients/4.webp" alt="" />
                        </li>
                        <li>
                            {/* <span className="client-logo5"></span> */}
                            <img src="/media/clients/5.webp" alt="" />
                        </li>
                        <li>
                            {/* <span className="client-logo6"></span> */}
                            <img src="/media/clients/6.webp" alt="" />
                        </li>
                        <li>
                            {/* <span className="client-logo6"></span> */}
                            <img src="/media/partners/Blaylock.png" alt="" />
                        </li>
                        <li>
                            {/* <span className="client-logo6"></span> */}
                            <img src="/media/partners/Shoreditch.png" alt="" />
                        </li>
                        <li>
                            {/* <span className="client-logo6"></span> */}
                            <img src="/media/partners/Rapid.png" alt="" />
                        </li>
                    </ul>
                </div> 
            </Row>
        </section>
    );
}
 
export default Clients;