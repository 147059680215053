import React, { useState } from 'react'
import { Formik, Form } from "formik";
import InputField from "../formFields/inputField";
import PhoneDropdown from "../formFields/phoneDropdown";
import TextAreaField from "../formFields/textAreaField.jsx";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { values } from "lodash-es";
import AlertMessage from "../AlertMessage"
import CheckIcon from '@mui/icons-material/Check';
import {Button,CircularProgress} from '@mui/material';

const ContactUsForm = ({ currentLocation }) => {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setseverity] = useState("");
  const [loading, setloading] = useState(false);

  const validate = Yup.object({
    fullName: Yup.string()
      .required("This is a required field")
      .matches(/^(?!\s*$).+/, "This is a required field")
      .max(15, "Must be 15 characters or less"),
    emailAddress: Yup.string().email("Email is invalid").required("This is a required field"),
  });
  const handleSubmit = (values, action) => {

    setloading(true)

    var new_msg = {
      id: 0,
      name: values.fullName.trim(),
      email: values.emailAddress,
      company: values.company,
      phoneNo: values.phoneNum,
      reference: values.reference,
      message: values.message
    };

    axios({
      method: 'post',
      url: 'https://devapp.digitallandscape.com.pk:1401/api/v1/User',
      data: new_msg
    })
      .then(result => {
        setloading(false)
        setMessage("Your Message Has Been Submitted Successfully!")
        setseverity("success")
        setOpen(true)
        action.resetForm();

      })
      .catch(error => {
        setMessage("Something went wrong.")
        setseverity("error")
        setOpen(true)
        console.log(error);
      });
  };
  return (
    <>
      <Formik
        initialValues={{
          fullName: "",
          emailAddress: "",
          company: "",
          phoneNum: "",
          reference: "",
          message: ""
        }}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <div>
            <h4 className="title-blue-bar mb-2">Contact Us</h4>
            <Form>
              <InputField name="fullName" placeholder="Full Name" />
              <InputField name="emailAddress" type="email" placeholder="Email Address" />
              <InputField name="company" placeholder="Company" />
              <PhoneDropdown currentLocation={currentLocation} name="phoneNum" placeholder="Phone Number" />
              <InputField name="reference" placeholder="How did you hear about us?" />
              <TextAreaField name="message" placeholder="Your Message" />
              <div className="text-right">
                {/* <button
                type="submit"
                className="contact-btn"
              >
                Submit
              </button> */}
              <Button style={{marginTop : "12px"}} type="submit" variant="contained" disabled={loading} startIcon={!loading ? <CheckIcon /> : <CircularProgress style={{width : "25px", height : "25px"}} />}>
                Submit
              </Button>
                {/* <LoadingButton
                  type="submit"
                  loading
                  loadingPosition="start"
                  startIcon={<CheckIcon />}
                  variant="outlined"
                >
                  Submit
                </LoadingButton> */}
              </div>
            </Form>
          </div>
        )}
      </Formik>
      <AlertMessage open={open} setOpen={setOpen} message={message} severity={severity} />
    </>
  );
};

export default ContactUsForm;
