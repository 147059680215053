// import { useSpeechRecognition } from 'react-speech-kit';
// import ic_send from '../assets/icons/ic_send.png'
import ic_send_disable from "../../media/chatbot/icons/ic_send_disable.svg";
import ic_send from "../../media/chatbot/icons/ic_send.svg";
import ic_mic from "../../media/chatbot/icons/ic_mic.png";
import ic_chat from "../../media/chatbot/icons/ic_chat.png";
import ic_logout from "../../media/chatbot/icons/ic_logout.png";
// import ic_mic from "../../assets/icons/ic_mic.png";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconButton from '@mui/material/IconButton';

import { Input, Image } from "antd";
import React, { useEffect, useRef, useState, useCallback, memo } from "react";
import { createUseStyles } from "react-jss";
import { Audio, Bars } from "react-loader-spinner";
import useSpeechToText from "react-hook-speech-to-text";
import MicIcon from '@mui/icons-material/Mic';
var MIC_IS_RECOGNIZING = false;

const InputBox = ({
  onPressSendButton,
  isInputEnable,
  onStopVideoAndVoice,
  setisSendButtonHidden,
  isSendButtonHidden,
  isRecording,
  toggleMic,
  CHAT_LIST_LENGTH
}) => {
  const videoViewRef = useRef();
  const {
    error,
    interimResult,
    // isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  const stopVideo = () => {
    videoViewRef.current.stopVideo();
  };

  // const { listen, listening, stop } = useSpeechRecognition({
  //     onResult: (result) => {
  //         // setText(result);
  //         // console.log(result)
  //     },
  // });
  const style = useStyle({ isRecording, isInputEnable, isSendButtonHidden });

  const [text, setText] = useState("");
  const inputRef = useRef();

  // const toggleMic = () => {
  //   stopVideo();
  //   window.cancelSpeak();
  //   if (isRecording) {
  //     stopSpeechToText();
  //     MIC_IS_RECOGNIZING = false;
  //   } else {
  //     startSpeechToText();
  //     MIC_IS_RECOGNIZING = true;
  //   }
  // };

  useEffect(() => {
    results.map((result, index) => {
      if (index === results.length - 1) {
        onPressSendButton(result?.transcript);
        // console.log(result?.transcript)
      }
    });
  }, [results]);

  useEffect(() => {
    if (isInputEnable) inputRef.current.focus();
  }, [isInputEnable]);

  const sendInputMessage = (msg) => {
    if (msg.trim() !== "") {
      setText("");
      onPressSendButton(msg);
    }
  };

  const onChangeText = (e) => {
    const { value } = e.target;
    setText(value);

    if (value.trim() !== "") {
      setisSendButtonHidden(false)
    }
    else {
      setisSendButtonHidden(true)
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendInputMessage(e.target.value);
    }
  };



  const onToggleMic = () => {
    // if(listening){
    //     stop()
    // }else{
    //     listen(false,'en-EN')
    // }
    onStopVideoAndVoice();
    if (isRecording) {
      stopSpeechToText();
    } else {
      // startSpeechToText(false, 'en-EN')
      startSpeechToText();
    }
  };

  return (
    // <div className={style.inputBox} id="inputBox" >
    <div className={style.inputBox}  id="inputBox">

      {!isRecording && <Input
        ref={inputRef}
        disabled={!isInputEnable}
        value={text}
        className={style.inputFiled}
        placeholder="Type or talk"
        onChange={onChangeText}
        onKeyDown={handleKeyDown}
        maxlength="256"
      />}
      {!isSendButtonHidden ?
        <IconButton
          style={{margin : "0px -2px 14px 0px"}}
          disabled={!isInputEnable}
          onClick={() => {
            sendInputMessage(text);
          }}
          className="chatbot-buttons" id="sendButtonn" variant="contained"
          aria-label="send">
          <SendIcon />
        </IconButton>
        : CHAT_LIST_LENGTH.length > 0 && !isRecording &&
        <IconButton
          style={{margin : "0px -2px 14px 0px"}}
          onClick={() => {
            toggleMic();
          }}
          className="chatbot-buttons" id="sendButtonn" variant="contained"
          aria-label="send">
          <MicIcon />
        </IconButton>
      }

    </div>
  );
};

const useStyle = createUseStyles({
  inputBox: {
    // width : (props) => (props.isSendButtonHidden ? "276px" : "100%"),
    height: "6%",
    marginTop: "0.8vh",
    backgroundColor: '#ffff',
    backgroundColor: (props) => (props.isInputEnable ? "#ffff" : "#ffff"),
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "6px",
    pointerEvents: (props) => (props.isInputEnable ? "auto" : "none"),
    webkitBoxShadow: "2px 4px 4px #9E9E9E",
    mozBoxShadow: "2px 4px 4px #9E9E9E",
    // boxShadow: "1px 1px 1px #ffff",
    // marginBottom: "0",
  },
  inputFiled: {
    // width: "100%",
    height: "40px",
    // fontSize: '2vw',
    // border: 'none',
    // marginBottom: '50px',
    marginBottom: "5vh",
    borderColor: "#429efa",
    borderRadius: "6px",
    padding: "px",
    // marginLeft:" 22px",
    outline: "none",
    // background: 'transparent',
    // backgroundColor:"white",
    backgroundColor: "white",

    "&:hover": {},
    "&:active": {
      borderColor: "white",
      borderWidth: 0,
    },
    "&::-webkit-input-placeholder": {
      /* Edge */ color: "#ffff",
    },
    "&::-ms-input-placeholder": {
      /* Internet Explorer 10-11 */ color: "#A3A3A3",
    },
    "&::placeholder": {
      color: "#A3A3A3",
    },
  },
  sendButton: {
    // width: "30px",
    // height: "30px",
    // margin: "3px",
    // padding: "2px",
    cursor: "pointer",
    // backgroundColor:"#ffff",
    // marginBottom: '50px',
    marginBottom: "5vh",
    // transform: 'rotate(-50deg)',
  },
  micContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    marginBottom: "50px",
    marginLeft: "10px",
  },
  micButtonCotainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    padding: "1%",
    // backgroundColor: (props) => props.isRecording ? 'pink' : 'trasnparent',
    borderRadius: 200,
    marginRight: "2%",
  },
  micButton: {
    width: "2vh",
    height: "4vh",
    marginRight: "2vh",
    marginLeft: "2vh",
    alignSelf: "center",
    border: 0,
    background: "transparent",
    objectFit: "contain",
    objectPosition: "center",
  },
  micButtonMobile: {
    width: "6.5vh",
    height: "6.5vh",
    background: "white",
    padding: "1vh",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: (props) => (props.isMicEnable ? "auto" : "none"),
    backgroundColor: (props) => (props.isMicEnable ? "white" : "#00000010"),
    cursor: "pointer",
    webkitBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    mozBoxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
    boxShadow:
      "rgb(73 78 96 / 35%) 1px 8px 8px -5px, rgb(74 79 97 / 35%) 10px 5px 10px -7px",
  },
  micButtonImage: {
    width: "3.5vh",
    height: "3.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
});

export default memo(InputBox);
